import api from "@/base/utils/request";

// 获取会员名录tab
export const memberTab = data => {
  return api({
    url: "/admin/cyc/Organization/membersContact",
    method: "post",
    data
  });
};

// 获取通讯录职务列表
export const memberPositions = data => {
  return api({
    url: "/admin/cyc/Organization/getContactPositions",
    method: "post",
    data
  });
};

// 保存通讯录职务
export const savePositions = data => {
  return api({
    url: "/admin/cyc/Organization/saveContactPositions",
    method: "post",
    data
  });
};