<template>
  <div class="list-page">
    <el-tabs style="margin-bottom: 22px" v-model="activeName" @tab-click="handleClick">
      <el-tab-pane :label="tab.name" :name="tab.id.toString()" v-for="tab in tabArr" :key="tab.id">
      </el-tab-pane>
    </el-tabs>
    <div class="list-content" v-loading="loading">
      <div style="margin-bottom: 20px">
        <el-radio v-model="ContactShow" :label="1" @change="handleChange">开启</el-radio>
        <el-radio v-model="ContactShow" :label="0" @change="handleChange">关闭</el-radio>
      </div>
      <el-table :data="ContactShow ? listCache[activeName] : listCache.close" class="thead-light" stripe
        style="width: 70%" id="sortTable" row-key="position_id">
        <!-- 序号 -->
        <el-table-column width="100" type="index" :index="indexMethod" label="序号"></el-table-column>
        <template v-for="(th, index) in thead">
          <el-table-column :prop="th.prop" :label="th.label" :width="th.width || ''" :key="index">
            <template slot-scope="scope">
              <!-- 显示 -->
              <el-checkbox v-if="th.prop === 'is_show'" :disabled="!ContactShow" v-model="scope.row[th.prop]">
              </el-checkbox>
              <!-- 普通 -->
              <span v-else>{{ scope.row[th.prop] | placeholder }}</span>
            </template>
          </el-table-column>
        </template>
        <!-- 排序 -->
        <el-table-column width="100" :index="indexMethod" label="拖拽排序">
          <span class="el-icon-sort my-handle cursor-pointer"></span>
        </el-table-column>
      </el-table>
    </div>
    <FixedActionBar>
      <el-button type="primary" @click="handleSave" :loading="saveloading">保存
      </el-button>
    </FixedActionBar>
  </div>
</template>

<script>
import {
  memberTab,
  memberPositions,
  savePositions,
} from "../../api/member/member-list";
import Sortable from "@/modules/page-renovation/js/Sortable.min.js";
import FixedActionBar from "@/base/layout/FixedActionBar";

import cloneDeep from 'lodash/cloneDeep'

export default {
  data() {
    return {
      tabArr: [],
      activeName: "",
      loading: false, //加载
      thead: [
        { label: "会内职务", prop: "name" },
        { label: "显示", prop: "is_show" },
      ],
      tbody: [],
      listCache: {},
      ContactShow: 0,
      saveloading: false,
    };
  },
  methods: {
    // 获取tab栏配置
    getMemberTab() {
      this.loading = true;
      memberTab()
        .then((res) => {
          if (res.data.length === 0) {
            this.loading = false;
            return;
          }
          this.tabArr = res.data;
          this.activeName =
            this.activeName != 0
              ? this.activeName
              : this.tabArr[0].id.toString();
          let activeData = this.tabArr.find(
            (item) => item.id == Number(this.activeName)
          );
          this.ContactShow =
            this.activeName != 0
              ? activeData.is_contact_show
              : this.tabArr[0].is_contact_show;
          this.getMemberPositions(Number(this.activeName));
        })
        .catch((err) => {});
    },
    // 获取职务列表
    getMemberPositions(id) {
      this.loading = true;
      memberPositions({ joinable_organization_id: id })
        .then((res) => {
          const { data } = res;
          data.forEach((element) => {
            element.is_show = element.is_show ? true : false;
          });
          this.$set(this.listCache, this.activeName, data);
          this.closeCache();
          this.loading = false;
        })
        .catch((err) => {
          this.loading = false;
        });
    },
    // 关闭时列表缓存
    closeCache() {
      let closeData = cloneDeep(this.listCache[this.activeName]);
      closeData.forEach((element) => {
        element.is_show = false;
      });
      if (this.listCache.close) {
        this.listCache.close = closeData;
      } else {
        this.$set(this.listCache, "close", closeData);
      }
    },
    // 拖拽排序
    drag() {
      const el = document.querySelectorAll(
        "#sortTable .el-table__body > tbody"
      )[0];
      Sortable.create(el, {
        disabled: false,
        ghostClass: "sortable-ghost",
        handle: ".my-handle",
        setData: function (dataTransfer) {},
        onEnd: (e) => {
          let arr = this.listCache[this.activeName]; // 获取表数据
          arr.splice(e.newIndex, 0, arr.splice(e.oldIndex, 1)[0]); // 数据处理
          this.$nextTick(() => {
            this.tbody = [];
            arr.forEach((item, index) => {
              this.tbody.push(item);
            });
            if (this.ContactShow) {
              this.listCache[this.activeName] = this.tbody;
            } else {
              const closeData = cloneDeep(arr);
              closeData.forEach((element) => {
                element.is_show = false;
              });
              this.listCache.close = closeData;
            }
          });
        },
      });
    },
    // 序号计算
    indexMethod(index) {
      return index + 1;
    },
    // 开启/关闭操作
    handleChange(e) {
      this.ContactShow = e ? 1 : 0;
    },
    // 切换tab栏
    handleClick(e) {
      const tabData = this.tabArr.find((item) => item.id === Number(e.name));
      this.ContactShow = tabData.is_contact_show;
      if (!this.listCache[e.name]) {
        this.getMemberPositions(Number(e.name));
      } else {
        this.closeCache();
      }
    },
    //保存
    handleSave() {
      this.saveloading = true;
      const dataList = this.ContactShow
        ? cloneDeep(this.listCache[this.activeName])
        : cloneDeep(this.listCache.close);
      dataList.forEach((element) => {
        element.is_show = element.is_show ? 1 : 0;
      });
      const data = {};
      data.joinable_organization_id = Number(this.activeName);
      data.is_contact_show = this.ContactShow;
      data.positions = JSON.stringify(dataList);
      savePositions(data)
        .then((res) => {
          this.$message.success(res.msg);
          this.saveloading = false;
          this.getMemberTab();
        })
        .catch((err) => {
          this.saveloading = false;
        });
    },
  },
  created() {
    console.log("created", this.activeName);
    this.getMemberTab(); //获取tab栏配置
  },
  mounted() {
    this.drag();
  },
  components: { FixedActionBar },
};
</script>
